export default function ({ route, app, $auth }) {
  try {
    // Skip tracking for development environment if needed
    if (process.env.NODE_ENV === 'development') {
      console.log('Skipping tracking for development environment')
      return
    }

    app.$fb.sendEvent({
      eventName: 'ViewContent',
      eventSourceUrl: `${process.env.VUE_APP_BASE_URL}${route.fullPath}`,
    })
  } catch (error) {
    console.error('Error tracking page view:', error)
  }
}
